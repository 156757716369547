import React from 'react'
import Icon from '@mdi/react';
import { mdiMapMarkerOutline } from '@mdi/js';
import Logo from '../media/drmlogo2-white.webp'

const Me = () => {
	return (
		<div className="first" id="main">
                
            <div className="headline">
				Hi, I'm Dajana! <br/><br/>Freelance Web Developer<br/><br/>	
               		 <br/><br/> 
			<Icon path={mdiMapMarkerOutline} title="Location" size={0.7}/> Sofia, BG
			</div>
		</div>
	)
}
export default Me
