import { US, RS, BG, DE } from 'country-flag-icons/react/3x2'
import { AnimationOnScroll } from 'react-animation-on-scroll';

import Certificate from '../media/certificates/meta-front-end-developer-certificate.png';
import IBM from '../media/certificates/cloud-native-devops-agile-nosql-essentials.png'
import IBM2 from '../media/certificates/front-end-development-with-react.png'
import JS from '../media/js.jpeg'
import CSS3 from '../media/CSS3.png'
import Node from '../media/node.png'
import React from '../media/react.png'
import HTML5 from '../media/html5.png'
import Figma from '../media/figma.png'
import Git from '../media/git.png'
import Redux from '../media/redux.png'
import Docker from '../media/docker.png'

const About = () => {
   
	return (
        <>
        <div className='carousels' id="about">
            <div className="carousel">
                <AnimationOnScroll initiallyVisible={false} animateIn="animate__slideInRight" >
                    <div className='card'>
                   
                         
                            <div className='brand'>
                            
                            
                            <div className='row'>
                            <img src={HTML5}/>
                            <img src={JS}/>
                            <img src={CSS3}/>
                            </div>
                            
                            <div className='row'>
                            <img src={React}/>
                            <img src={Node}/>
                            <img src={Figma}/>
                            </div>
                            
                            <div className='row'>
                            <img src={Git}/>
                            <img src={Redux}/>
                            <img src={Docker}/>
                       </div></div>
                    </div>
                
                    
                
                    
                    </AnimationOnScroll>
               
            </div>
            
            
            
            
        
            
               
            </div>
             <div className="certificate">
                    <div className="photo_container"><a href="https://www.credly.com/badges/89eaef42-ddea-4e31-adc5-046a516e1068/public_url" alt="https://www.credly.com/badges/89eaef42-ddea-4e31-adc5-046a516e1068/public_url" title="Digital Certificate" target="_blank" rel="noopener noreferrer"><img src={Certificate} className="container"/></a>
               </div>
                    <div className="photo_container"><a href="https://www.credly.com/badges/7467a3e2-7b06-4a3a-a21c-6aa88f250dbb/public_url" alt="https://www.credly.com/badges/89eaef42-ddea-4e31-adc5-046a516e1068/public_url" title="Digital Certificate" target="_blank" rel="noopener noreferrer"><img src={IBM} className="container"/></a>
               </div>
                    <div className="photo_container"><a href="https://www.credly.com/badges/94c91db8-53af-4b66-911c-08f6a6749a15/public_url" alt="https://www.credly.com/badges/89eaef42-ddea-4e31-adc5-046a516e1068/public_url" title="Digital Certificate" target="_blank" rel="noopener noreferrer"><img src={IBM2} className="container"/></a>
               </div>
            </div>
            </>
       
  )
}

export default About